<template>
  <div class="widget-item" :id="widget.object.wid" :style="style">
    <TemplateViewer ref="TemplateViewer" :template="widget.object" :assocId="widget.assoc_id" />
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import TemplateViewer from '@/components/widgets/templates/TemplateViewer.vue';
  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'TemplateWidget',

    components: { TemplateViewer },

    mixins: [simpleTypeMixin, playerMixins],

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return Object.assign(style, {
          position: 'absolute',
        });
      },
    },

    methods: {},
  };
</script>

<style lang="scss" scoped>
  .widget-item {
    position: absolute;
    overflow: hidden;
  }
</style>
