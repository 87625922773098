<template>
  <div ref="weatherContainer" class="weather-container" :style="{ backgroundColor: background }">
    <div v-if="loading" class="loading">
      <img src="@/assets/img/weather/loader.svg" alt="loading" />
    </div>

    <div v-else-if="error" class="weather-error">Unable to fetch weather data</div>

    <div v-else-if="weatherData" :style="containerStyle">
      <div
        v-if="!app.weather || app.weather.type === WEATHER_DISPLAY_TYPE.NOW.value"
        class="weather-simple"
      >
        <div class="now-text">
          <div class="now-city" :style="titleStyle">
            {{ language === WEATHER_LANGUAGES.ENGLISH.value ? 'Now' : 'Nu' }}
          </div>

          <div
            v-show="currentTemp || currentTemp === 0"
            class="now-temperature"
            :style="nowTempStyle"
          >
            {{ currentTemp }}<sup>&deg;</sup>
          </div>

          <div class="now-city" :style="titleStyle">
            {{ cityName }}
          </div>
        </div>

        <div class="now-icon" :style="simpleIconStyle">
          <WeatherIcons :iconId="weatherData[0].WeatherIcon" :color="app.weather?.iconColor" />
        </div>
      </div>

      <div v-else class="weather-multiple">
        <div class="multiple-title" :style="titleStyle">
          {{ multipleTitle }}
        </div>

        <div class="multiple-items" :style="multipleItemsContainerStyle">
          <div
            class="item-container"
            v-for="(item, index) of weatherItems"
            :key="index"
            :style="itemStyle"
          >
            <div class="multiple-center">
              <div class="multiple-temperature">
                <div class="temperature" :style="multipleNumbersStyle">
                  {{ item.temperature }}
                </div>

                <div class="lower-temperature" :style="lowerTempStyle">
                  {{ item.minTemperature }}
                </div>
              </div>

              <div class="multiple-icon" :style="multipleIconStyle">
                <WeatherIcons
                  :iconId="item.icon"
                  :color="app.weather ? app.weather.iconColor : defaultValues.iconColor"
                />
              </div>

              <div class="multiple-time" :style="multipleNumbersStyle">
                {{ app.weather.type === WEATHER_DISPLAY_TYPE.HOURS.value ? item.hour : item.day }}
              </div>
            </div>
          </div>
        </div>

        <div class="now-city" :style="titleStyle">
          {{ cityName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash';
  import { computed, ref, toRef } from 'vue';
  import { useResizeObserver } from '@vueuse/core';
  import moment from 'moment-timezone';
  import localforage from 'localforage';

  import WeatherIcons from '@/components/apps/weather/WeatherIcons.vue';

  import { apiWeatherCurrentConditions } from '@/api/weather';

  import {
    weatherIcons,
    weatherDefaultOptions,
    WEATHER_APP_SCALES,
    WEATHER_DAY_FORMAT,
    WEATHER_DISPLAY_TYPE,
    WEATHER_HOURS_FORMAT,
    WEATHER_TEMPERATURE,
    WEATHER_LANGUAGES,
  } from '@/constants/weatherApp';
  import { cacheData } from '@/helpers/caching';

  export default {
    name: 'WeatherView',

    emits: ['saveData'],

    components: {
      WeatherIcons,
    },

    props: {
      app: {
        type: Object,
      },
    },

    data() {
      return {
        loading: false,
        error: false,
        appData: null,
        fetcherTimer: null,
        currentLocation: null,
        WEATHER_DISPLAY_TYPE,
        WEATHER_LANGUAGES,
        defaultValues: weatherDefaultOptions,
      };
    },

    filters: {
      round(num) {
        return parseInt(num).toFixed(0);
      },
    },

    mounted() {
      if (!this.weatherData) this.initWeatherView();

      this.fetcherTimer = setInterval(this.fetchData, 10 * 60 * 1000);
    },

    beforeDestroy() {
      clearInterval(this.fetcherTimer);
    },

    computed: {
      background() {
        return this.app.weather ? this.app.weather.backgroundColor : this.app.background.color;
      },

      containerStyle() {
        const { width, height } = this.getContainerSize();

        const paddingBottom = height * this.currentScales.padding.bottom;
        const paddingLeft = width * this.currentScales.padding.left;
        const paddingRight = width * this.currentScales.padding.right;
        const paddingTop = height * this.currentScales.padding.top;

        return {
          color: this.app.weather ? this.app.weather.fontColor : '',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: `${paddingBottom}px`,
          paddingLeft: `${paddingLeft}px`,
          paddingRight: `${paddingRight}px`,
          paddingTop: `${paddingTop}px`,
          fontFamily: this.app.weather ? this.app.weather.textFont : 'Arial',
          height: height ? `${height}px` : '100%',
          width: width ? `${width}px` : '100%',
        };
      },

      multipleTitle() {
        const isHours = this.app.weather
          ? this.app.weather.type
          : '' === WEATHER_DISPLAY_TYPE.HOURS.value;

        if (this.app.weather.language === WEATHER_LANGUAGES.SWEDISH.value) {
          return isHours ? 'Kommande timmar' : 'Kommande vecka';
        }

        return isHours ? 'The next hours' : 'The next days';
      },

      nowTempStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * WEATHER_APP_SCALES.now.numbers.fontSize}px`,
          lineHeight: `${height * WEATHER_APP_SCALES.now.numbers.lineHeight}px`,
        };
      },

      titleStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * WEATHER_APP_SCALES.now.title.fontSize}px`,
          lineHeight: `${height * WEATHER_APP_SCALES.now.title.lineHeight}px`,
          color: this.app.weather ? this.app.weather.fontColor : '',
        };
      },

      simpleIconStyle() {
        const { width: iconWidth = 100, height: iconHeight = 100 } = this.weatherIcon;

        const widthSize = this.getContainerSize().width * WEATHER_APP_SCALES.now.icon.width;

        return {
          width: `${0.85 * iconWidth > iconHeight ? widthSize : widthSize * 0.85}px`,
        };
      },

      multipleIconStyle() {
        return {
          width: `${this.getContainerSize().width * WEATHER_APP_SCALES.multiple.icon}px`,
        };
      },

      itemStyle() {
        return {
          minWidth: `${this.getContainerSize().width * WEATHER_APP_SCALES.multiple.itemWidth}px`,
        };
      },

      multipleItemsContainerStyle() {
        const { height } = this.getContainerSize();

        return {
          height: `${
            height *
            WEATHER_APP_SCALES.multiple.bodyHeight[this.app.weather ? this.app.weather.type : '']
          }px`,
        };
      },

      multipleNumbersStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * WEATHER_APP_SCALES.multiple.numbers.fontSize}px`,
          lineHeight: `${
            height * WEATHER_APP_SCALES.multiple.numbers.lineHeight * (this.is24Format ? 1 : 0.75)
          }px`,
          color: this.app.weather ? this.app.weather.fontColor : '#999999',
        };
      },

      lowerTempStyle() {
        const { height } = this.getContainerSize();

        return {
          fontSize: `${height * WEATHER_APP_SCALES.multiple.lowerTemp.fontSize}px`,
          lineHeight: `${height * WEATHER_APP_SCALES.multiple.lowerTemp.lineHeight}px`,
          color: this.app.weather ? this.app.weather.fontColor : '',
        };
      },

      location() {
        return this.app.weather &&
          this.app.weather.location &&
          this.app.weather.location.coordinates
          ? this.app.weather.location.coordinates
          : this.app.location || [];
      },

      cityName() {
        const cityName =
          this.app.weather && this.app.weather.location ? this.app.weather.location.label : null;

        if (!cityName)
          return this.weatherData && this.weatherData[0] ? this.weatherData[0].WeatherText : '';

        return cityName;
      },

      measurementSystem() {
        const temperature = this.app.weather
          ? this.app.weather.temperatureUnit
          : this.app.temp_unit;

        return !this.app.weather || temperature === WEATHER_TEMPERATURE.CELSIUS.value
          ? 'Metric'
          : 'Imperial';
      },

      is24Format() {
        return (
          parseInt(this.app.weather ? this.app.weather.timeFormat : this.app.time_format) === 24
        );
      },

      currentTemp() {
        if (
          this.app.weather &&
          this.app.weather.type &&
          this.app.weather.type !== WEATHER_DISPLAY_TYPE.NOW.value
        )
          return '';

        const temperature = this.weatherData[0]
          ? this.weatherData[0].Temperature[this.measurementSystem].Value
          : null;

        if (!temperature) return '';

        return Math.floor(temperature);
      },

      language() {
        return this.app.weather ? this.app.weather.language : this.app.lang;
      },

      timeFormat() {
        return this.app.weather ? this.app.weather.timeFormat : this.app.time_format || 24;
      },

      weatherIcon() {
        const iconCode = this.weatherData[0]
          ? this.weatherData[0].WeatherIcon
          : this.weatherItems[0].icon;

        return weatherIcons[iconCode];
      },

      dayFormat() {
        if (this.app.weather && this.app.weather.dayFormat === WEATHER_DAY_FORMAT.SHORT.value) {
          return WEATHER_DAY_FORMAT.SHORT.format;
        } else if (
          this.app.weather &&
          this.app.weather.dayFormat === WEATHER_DAY_FORMAT.DAY_OF_MONTH.value
        ) {
          return WEATHER_DAY_FORMAT.DAY_OF_MONTH.format;
        }

        return WEATHER_DAY_FORMAT.WORD.format;
      },

      weatherData() {
        return this.$store.state.player.appsData[this.app.item_id]
          ? this.$store.state.player.appsData[this.app.item_id].data
          : null;
      },

      weatherItems() {
        if (!this.app.weather || this.app.weather.type === WEATHER_DISPLAY_TYPE.NOW.value) {
          return [];
        }

        const isDailyForecast = this.app.weather.type === WEATHER_DISPLAY_TYPE.DAYS.value;

        const items = isDailyForecast
          ? this.weatherData.DailyForecasts
          : this.weatherData.slice(0, 7);

        return items.map((weather) => {
          const hourFormat = this.is24Format
            ? WEATHER_HOURS_FORMAT.TWENTY_FOUR.format
            : WEATHER_HOURS_FORMAT.TWELVE.format;
          const temperature = isDailyForecast
            ? `${Math.floor(weather.Temperature.Maximum.Value)}º`
            : `${Math.floor(weather.Temperature.Value)}º`;

          return {
            temperature,
            minTemperature: weather.Temperature.Minimum
              ? `${Math.floor(weather.Temperature.Minimum.Value)}º`
              : '',
            icon: isDailyForecast ? weather.Day.Icon : weather.WeatherIcon,
            hour: isDailyForecast ? '' : moment(weather.DateTime).format(hourFormat),
            day: moment(isDailyForecast ? weather.Date : weather.DateTime).format(this.dayFormat),
          };
        });
      },
    },

    methods: {
      async initWeatherView() {
        this.error = false;
        this.loading = true;

        try {
          await this.fetchData();
        } catch (error) {
          console.log('error: ', error);
          this.error = true;
        }

        this.loading = false;
      },

      async fetchData() {
        try {
          const response = await apiWeatherCurrentConditions(
            this.location ? this.location.join(',') : [],
            this.language,
            this.app.weather ? this.app.weather.type : undefined,
            this.measurementSystem === 'Metric',
          );

          let data = response ? response.data : null;

          if (!response) {
            data = await localforage.getItem(`weather-app-${this.app.item_id}`);

            if (!data) {
              throw new Error(`Weather cache fallback failed. Name: ${this.cityName}`);
            }
          }

          this.$emit('saveData', data);
          cacheData(`weather-app-${this.app.item_id}`, data);
        } catch (error) {
          console.log('Error Fetching weather data', error);
        }
      },
    },

    setup(props) {
      const app = toRef(props, 'app');

      function getCurrentScales() {
        const isNowType = app.weather && app.weather.type === WEATHER_DISPLAY_TYPE.NOW.value;
        const scaleType = isNowType ? 'now' : 'multiple';

        return WEATHER_APP_SCALES[scaleType];
      }

      const weatherContainer = ref(null);
      const containerHeight = ref(null);
      const containerWidth = ref(null);

      const currentScales = computed(getCurrentScales);

      function getContainerSize() {
        const maxHeight =
          containerHeight.value / containerWidth.value < currentScales.value.container
            ? containerHeight.value
            : containerWidth.value * currentScales.value.container;
        const maxWidth = maxHeight / currentScales.value.container;

        return {
          height: maxHeight,
          width: maxWidth,
        };
      }

      useResizeObserver(
        weatherContainer,
        debounce((entries) => {
          const container = entries[0];
          containerHeight.value = container.contentRect.height;
          containerWidth.value = container.contentRect.width;
        }, 500),
      );

      return {
        containerHeight,
        containerWidth,
        currentScales,

        // methods
        getContainerSize,
        weatherContainer,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .loading,
  .weather-error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .weather-container {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    font-family: 'Poppins';
    color: #151515;

    .now-city {
      color: #6a6b6a;
      text-align: left;
      white-space: normal;
    }

    .multiple-title {
      text-align: left;
    }

    .weather-simple {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .now-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        line-height: normal;
        height: 100%;
      }

      .now-icon {
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .weather-multiple {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;

      .multiple-items {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .item-container {
        align-items: center;
        height: 100%;

        .multiple-temperature {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          font-weight: bold;
        }

        .temperature {
        }

        .multiple-center {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          height: 100%;
        }

        .multiple-time {
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }
</style>
