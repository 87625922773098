import config from '../config';
import http from '@/utils/http';

export async function apiGetScreenPlaylistItem(playlistId, screenId) {
  try {
    return await http.get(
      `${config.apiUrl}userapi/screens/${screenId}/playlist/${playlistId}/children`,
    );
  } catch (error) {
    console.log(`Playlist's items API failed: ${error?.message}. Playlist: ${playlistId}`);

    return null;
  }
}
