import { getCloudfrontUrl } from '@/helpers/utils';
import { apiGetFonts } from '@/api/fonts';
import { createFontFaceStyle, preLoadFont } from '@/utils/loadPicflowFonts/utils';
import { SentryLogger } from '@/utils/sentryLogger';
import { fontExistsInInternalStorage, getFontLocalUrl } from '@/utils/mediaAsset';
import { PreloadFont } from '@/utils/loadPicflowFonts/types';

export async function loadPicflowFonts() {
  try {
    const fonts = await apiGetFonts();
    const preloadFonts: PreloadFont[] = [];

    for (const font of fonts) {
      const fontUrl = getCloudfrontUrl(font.key);
      const existsLocally = await fontExistsInInternalStorage(fontUrl, font.id, font.organisation);

      preloadFonts.push({
        id: font.id,
        fontUrl: fontUrl,
        fontLocalUrl: getFontLocalUrl(fontUrl, font.id, font.organisation),
        existsLocally: existsLocally,
        name: font.name,
        format: font.key.split('.').pop()?.toLowerCase(),
      });
    }

    for (const font of preloadFonts) {
      if (font.format === null) {
        SentryLogger.error(`Font id ${font.id} format is null won't preload`);
        continue;
      }

      try {
        await preLoadFont(
          font.existsLocally ? font.fontLocalUrl : font.fontUrl,
          font.format ?? '',
          font.name,
        );
      } catch (error) {
        SentryLogger.error(`Failed to preload font ${font.fontUrl}`);
      }
    }

    for (const font of preloadFonts) {
      await createFontFaceStyle(font.existsLocally ? font.fontLocalUrl : font.fontUrl, font.name);
    }
  } catch (error: any) {
    SentryLogger.error('Failed to load fonts', error);
  }
}
