import localforage from 'localforage';

import { GET_PLAYLIST_ITEMS } from '../actions/playlist';
import { SET_PLAYLIST_CHILDREN } from '../actions/player';
import { cacheData } from '@/helpers/caching';

import { apiGetScreenPlaylistItem } from '../../api/playlist';

const state = {
  playlists: [],
};

const getters = {};

const actions = {
  [GET_PLAYLIST_ITEMS]: async ({ commit, rootState }, { playlistId, assocId }) => {
    const { screenId } = rootState.player;

    try {
      const response = await apiGetScreenPlaylistItem(playlistId, screenId, false);

      if (!response) {
        const playlistItems = await localforage.getItem(`playlist-items-${playlistId}-${assocId}`);

        if (!playlistItems)
          throw new Error(`Playlist's items cache fallback failed. Playlist: ${playlistId}`);

        commit(SET_PLAYLIST_CHILDREN, { playlistItems, assocId }, { root: true });
        return;
      }

      const data = response.data;

      if (!data.items) throw new Error(data);

      let items = data.items;

      if (data.overridden_items) {
        items = data.overridden_items.map((overridden_item, index) => ({
          ...overridden_item,
          ...(overridden_item.override ? overridden_item.override.config : {}),
          item_priority:
            overridden_item.override?.config.item_priority ||
            overridden_item.item_priority ||
            index,
        }));
      }

      items = items.sort((item1, item2) => item1.item_priority - item2.item_priority);

      const playlistItems = items.reduce((itemsObject, item) => {
        itemsObject[item.assoc_id] = item;

        return itemsObject;
      }, {});

      await cacheData(`playlist-items-${playlistId}-${assocId}`, playlistItems);

      commit(SET_PLAYLIST_CHILDREN, { playlistItems, assocId }, { root: true });
    } catch (error) {
      console.log('error: ', error?.message);
    }
  },
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
