import localforage from 'localforage';

export class InternalFileStore {
  static getInternalFileInstance() {
    return localforage.createInstance({
      name: 'InternalFileDB',
      storeName: 'InternalFile',
    });
  }

  static async setItemOrUpdate(localFileUrl, data) {
    if (!localFileUrl) {
      return;
    }

    const item = await InternalFileStore.getItem(localFileUrl);

    if (item === null) {
      const now = new Date().toISOString();

      await InternalFileStore.setItem(localFileUrl, {
        ...data,
        localstorageCreatedAt: now,
        localstorageUpdatedAt: now,
      });
      return;
    }

    var itemId = item?.itemId;
    var itemSize = item?.itemSize;
    var itemUrl = item?.itemUrl;
    var itemLocalUrl = item?.itemLocalUrl;
    var fontName = item?.fontName;

    if (data?.itemId && data?.itemSize && data?.itemUrl && data?.itemLocalUrl && data?.fontName) {
      itemId = data.itemId;
      itemSize = data.itemSize;
      itemUrl = data.itemUrl;
      itemLocalUrl = data.itemLocalUrl;
      fontName = data.fontName;
    }

    await InternalFileStore.setItem(localFileUrl, {
      ...data,
      itemId,
      itemSize,
      itemUrl,
      itemLocalUrl,
      fontName,
      localstorageCreatedAt: item.localstorageCreatedAt,
      localstorageUpdatedAt: new Date().toISOString(),
    });
  }

  static setItem(localFileUrl, data) {
    const store = InternalFileStore.getInternalFileInstance();
    return store.setItem(localFileUrl, data);
  }

  static getItem(localFileUrl) {
    const store = InternalFileStore.getInternalFileInstance();
    return store.getItem(localFileUrl);
  }

  static async removeItem(localFileUrl) {
    const store = InternalFileStore.getInternalFileInstance();

    try {
      await store.removeItem(localFileUrl);
    } catch (error) {
      // do nothing if item does not exist
    }
  }

  static async removeItems(localFileUrls) {
    for (const localFileUrl in localFileUrls) {
      await this.removeItem(localFileUrl);
    }
  }

  static async getKeys() {
    const store = InternalFileStore.getInternalFileInstance();
    return await store.keys();
  }

  static clear() {
    const store = InternalFileStore.getInternalFileInstance();
    return store.clear();
  }

  static async getStore() {
    const store = InternalFileStore.getInternalFileInstance();

    const allItems = [];

    await store.iterate((value, key) => {
      allItems.push(value);
    });

    return allItems;
  }
}
