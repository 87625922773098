<template>
  <div id="app">
    <RefreshTokenText v-if="initialRefreshTokenLoading" />
    <DownloadingMediaAssetLoader v-else-if="isSynchronizingMediaAssets || isLoadingFonts" />
    <router-view v-else />

    <PlayerModal v-if="showPlayerModal" />
  </div>
</template>

<script>
  import RefreshTokenText from '@/components/RefreshTokenText.vue';
  import PlayerModal from '@/components/player/PlayerModal.vue';
  import DownloadingMediaAssetLoader from '@/components/common/ui/dowloadingMediaAssetLoader/DownloadingMediaAssetLoader.vue';
  import { loadPicflowFonts } from '@/utils/loadPicflowFonts/loadPicflowFonts';
  import PicflowSentryPlugin from '@/plugins/picflowSentryPlugin';
  import { isLoggedIn } from '@/services/auth';
  import config from '@/config';
  import {
    SESSION_TOKEN_ENUM,
    PLAYER_ORIENTATION,
    SCREEN_ORIENTATION,
    SCREEN_ID_ENUM,
    SCREEN_LAYOUT_ENUM,
  } from './constants';
  import { getDeviceSpecificParam, setUniqueScreenCode } from '@/helpers/utils';
  import { FileSynchronizationManager } from '@/utils/internalFileStorage/fileSynchronizationManager';
  import { getWebOsDeviceInfo, webOSAppInitialization } from '@/utils/webOS';
  import { refreshAuthToken } from '@/api/login';
  import { getScreenEvents, saveScreenEvents, saveDeviceInfo } from '@/api/screenEvent';
  import localforage from 'localforage';
  import { fetchScreenLayout } from '@/services/layout';
  import { SET_PLAYER_ROTATION } from '@/store/actions/player';
  import { SCREEN_EVENT_INTERVAL_SECS } from '@/config/constants';

  window.jQuery = require('jquery');
  require('./plugins/jquery.textfill.min');

  const default_layout = 'dashboard';

  export default {
    name: 'app',
    components: { DownloadingMediaAssetLoader, RefreshTokenText, PlayerModal },
    data() {
      return {
        initialRefreshTokenLoading: true,
        isSynchronizingMediaAssets: false,
        isLoadingFonts: false,
      };
    },

    setup() {
      setUniqueScreenCode();
    },

    methods: {
      async startSynchronizingMediaAssets() {
        if (window.isWebOS && this.isSynchronizingMediaAssets === false) {
          this.isSynchronizingMediaAssets = true;

          const maxRetries = 3;
          var retryCount = 0;
          var allFileSynced = false;

          while (!allFileSynced && retryCount < maxRetries) {
            allFileSynced = await FileSynchronizationManager.runFileSynchronizationOnce();

            if (!allFileSynced) {
              const exponentialBackoffDelay = Math.pow(retryCount, 2) * 1000;
              await new Promise((resolve) => {
                setTimeout(resolve, exponentialBackoffDelay);
              });

              ++retryCount;
              console.log(`Sync retry count ${retryCount}`);
            }
          }

          this.isSynchronizingMediaAssets = false;
        }
      },

      async startLoadingFonts() {
        this.isLoadingFonts = true;
        try {
          await loadPicflowFonts();
        } catch (error) {
          console.error(`Failed to load fonts`);
        }
        this.isLoadingFonts = false;
      },

      async refreshToken() {
        try {
          const token = await refreshAuthToken();
          localStorage.setItem(SESSION_TOKEN_ENUM, token['session_token']);
          localforage.setItem(SESSION_TOKEN_ENUM, token['session_token']);
          this.initialRefreshTokenLoading = false;
        } catch (err) {
          console.log(err);
        }
      },

      async checkScreenStatus() {
        try {
          const screenEvents = await getScreenEvents();
          this.handleScreenEvents(screenEvents);
        } catch (err) {
          console.log(err);
        }
      },

      async handleScreenEvents(screenEvents) {
        // Logout only if screen code does not match. Needed to prevent running multiple screens from same CMS screen
        if (screenEvents.logout && !screenEvents.code_match) {
          await saveScreenEvents({ logout: false });
          localStorage.clear();
          localforage.clear();
          if (window.isWebOS === true) {
            await FileSynchronizationManager.removeAllInternalFiles();
          } else if (window.isAndroid === true) {
            window.logout();
          }
          window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
        }

        // If screen code does not match, it is an invalid screen and therefore ignoring rest of the events.
        if (!screenEvents.code_match) return;

        if (screenEvents.publish || screenEvents.refresh) {
          await saveScreenEvents({
            ...(screenEvents.publish && { publish: false }),
            ...(screenEvents.refresh && { refresh: false }),
          });
          const screenId = localStorage.getItem(SCREEN_ID_ENUM);
          const result = await fetchScreenLayout(screenId);
          if (result.success && result.data.status === 200) {
            localStorage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);
            localforage.setItem(SCREEN_LAYOUT_ENUM, result.data.data.layout_id);
          }
          window.location.href = `${window.location.origin}?${getDeviceSpecificParam()}`;
        }

        if (screenEvents.change_orientation && screenEvents.orientation) {
          await saveScreenEvents({ changeOrientation: false });
          const cb = () => {
            console.log('callback called from orientation');
            if (
              screenEvents.orientation === SCREEN_ORIENTATION.LANDSCAPE ||
              screenEvents.orientation === SCREEN_ORIENTATION.REVERSE_LANDSCAPE
            ) {
              this.$store.commit(SET_PLAYER_ROTATION, true);
            } else {
              this.$store.commit(SET_PLAYER_ROTATION, false);
            }
          };
          // Saved orientation information to force correct the orientation in android devices from App.vue on subsequent app restarts.
          localStorage.setItem(PLAYER_ORIENTATION, screenEvents.orientation);
          if (window.changeOrientation) {
            window.changeOrientation(screenEvents.orientation, cb);
          } else {
            cb();
          }
        }
      },
    },

    computed: {
      layout() {
        return (this.$route.meta.layout || default_layout) + '-layout';
      },

      showPlayerModal() {
        return this.$store.state.player.showPlayerModal;
      },
    },

    async created() {
      window.setDeviceInfo = saveDeviceInfo;
      if (isLoggedIn()) {
        this.refreshToken();
      } else {
        this.initialRefreshTokenLoading = false;
      }

      window.refreshTokenTimer = setInterval(() => {
        if (isLoggedIn()) this.refreshToken();
      }, 1000 * 60 * 10);

      window.screenStatusMonitor = setInterval(() => {
        if (isLoggedIn()) this.checkScreenStatus();
      }, 1000 * SCREEN_EVENT_INTERVAL_SECS);

      window.deviceInfoTimer = setInterval(async () => {
        if (isLoggedIn()) {
          if (window.isWebOS) {
            const deviceInfo = await getWebOsDeviceInfo();
            await saveDeviceInfo(deviceInfo);
          } else if (window.getDeviceInfo) {
            // android and web
            window.getDeviceInfo();
          }
        }
      }, 1000 * 60);
    },

    async mounted() {
      // should only run in production
      if (
        config.sentry.startSessionReplayOnMounted &&
        (window.isWebOS === true || window.isAndroid === true)
      ) {
        PicflowSentryPlugin.dispatchStartSessionReplayEvent();
      }

      if (window.isWebOS === true) {
        // we need to wait for all external scripts before we run webOS native code
        await webOSAppInitialization();
      }

      if (window.isAndroid) {
        // Force android to change orientation based on value saved in localstorage.
        // Otherwise the app always starts in landscape mode after app restart.
        const orientation = localStorage.getItem(PLAYER_ORIENTATION);
        if (window.changeOrientation && orientation) {
          window.changeOrientation(orientation);
        }
      }

      if (isLoggedIn()) {
        await this.startSynchronizingMediaAssets();
        // load fonts need to be after startSynchronizingMediaAssets
        await this.startLoadingFonts();
      }
    },

    beforeDestroy() {
      if (window.deviceInfoTimer) clearInterval(window.deviceInfoTimer);
      if (window.refreshTokenTimer) clearInterval(window.refreshTokenTimer);
      if (window.screenStatusMonitor) clearInterval(window.screenStatusMonitor);
    },
  };
</script>
