<template>
  <div
    ref="templateContainer"
    v-if="template"
    class="template-main-container"
    :style="templateStyle"
  >
    <template v-if="!isLoading">
      <TemplateLayer v-for="widget in widgets || []" :key="widget.assoc_id" :widget="widget" />
    </template>
    <div v-else class="loader">
      <div class="loader-background">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
  import localforage from 'localforage';

  import TemplateLayer from '@/components/widgets/templates/TemplateLayer.vue';
  import Loader from '@/components/common/Loader.vue';

  import { formatTemplateWidgets, getRBGAColor } from '@/helpers/utils';
  import { cacheData } from '@/helpers/caching';
  import { SET_PLAYLIST_TEMPLATE_ITEMS, SET_TEMPLATE_ITEMS } from '@/store/actions/player';

  import { apiGetScreenTemplateChildren } from '@/api/templates';

  export default {
    name: 'TemplateViewer',

    components: {
      TemplateLayer,
      Loader,
    },

    props: {
      template: {
        type: Object,
        default: () => null,
      },

      zoom: {
        type: Number,
        default: 1,
      },

      playlistId: {
        type: String,
        default: '',
      },

      assocId: {
        type: Number,
      },
    },

    data() {
      return {
        isLoading: false,
      };
    },

    mounted() {
      this.getWidgets();
    },

    watch: {
      template(value) {
        if (value) {
          this.getWidgets();
        }
      },
    },

    computed: {
      templateStyle() {
        const template = this.template?.settings || {};

        return {
          backgroundColor: getRBGAColor(template.backgroundColor, template.opacity / 100),
          width: `100%`,
          height: `100%`,
          overflow: 'hidden',
          zoom: this.zoom,
        };
      },

      widgets() {
        return this.assocId
          ? this.$store.state.player.templateItems[this.assocId]
          : this.$store.state.player.templateOnPlaylistItems[this.playlistId];
      },
    },

    methods: {
      async getWidgets() {
        try {
          if (this.widgets) return;

          const { screenId } = this.$store.state.player;
          const templateId = this.template.template_id;
          const storeMutation = this.assocId ? SET_TEMPLATE_ITEMS : SET_PLAYLIST_TEMPLATE_ITEMS;
          const storeKey = this.assocId || this.playlistId;
          this.isLoading = true;

          const response = await apiGetScreenTemplateChildren(
            templateId,
            screenId,
            this.playlistId,
          );

          if (!response) {
            const widgets = await localforage.getItem(
              `template-items-${this.playlistId}-${this.assocId}-${templateId}`,
            );

            if (!widgets) {
              this.$store.commit(storeMutation, { key: storeKey, widgets: null });
              throw new Error(
                `Template's children cache fallback failed. Template: ${templateId}. ${
                  this.playlistId ? `Playlist: ${this.playlistId}` : ''
                }`,
              );
            }

            this.$store.commit(storeMutation, { key: storeKey, widgets });

            return;
          }

          const formatedWidgets = formatTemplateWidgets(response.data);

          await cacheData(
            `template-items-${this.playlistId}-${this.assocId}-${templateId}`,
            formatedWidgets,
          );

          this.$store.commit(storeMutation, { key: storeKey, widgets: formatedWidgets });
        } catch (error) {
          console.log('getWidgets ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      },
    },

    provide() {
      return {
        template: () => this.template,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .template-main-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
  }
</style>
