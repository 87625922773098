<template>
  <div class="rss">
    <img
      v-if="rssRequestStatus === 'loading'"
      src="@/assets/img/rss/preloader.svg"
      class="rss-loading"
    />

    <div v-else-if="rssRequestStatus === 'error' && rssRequestError" class="rss-error p-3">
      <h5>{{ rssRequestError }}</h5>
    </div>

    <template v-else-if="rssFeed">
      <transition-group
        name="fade"
        mode="in-out"
        :duration="500"
        tag="div"
        style="width: 100%; height: 100%; overflow: hidden"
      >
        <template v-for="(item, index) in rssFeed.items">
          <div
            v-if="index === currentIndex"
            class="rss-slides"
            :class="[setting.rssTheme]"
            :key="index"
          >
            <div class="slide-container">
              <div
                v-if="setting.display.image"
                class="item-image"
                :style="{ width: setting.display.imageWidth + '%' }"
              >
                <img
                  :src="item.teaserImage || rssFeed.image || '@/assets/img/rss/rss_invalid.svg'"
                  :alt="item.title"
                  :style="{ objectFit: setting.display.imageMode || 'cover' }"
                />
              </div>

              <div
                class="item-body"
                :style="{
                  width: (setting.display.image ? 100 - setting.display.imageWidth : 100) + '%',
                  padding: setting.display.padding + 'px',
                }"
              >
                <div
                  v-if="setting.display.rssName"
                  class="item-header"
                  :style="{
                    'margin-bottom': setting.display.spacing + 'px',
                  }"
                >
                  <div class="rss-icon-container">
                    <img src="@/assets/img/rss/rss-icon.png" class="rss-icon" />
                  </div>

                  <div
                    class="header-title"
                    :style="{
                      fontSize: setting.display.feedNameSize + 'px',
                      lineHeight: setting.display.feedNameSize * 1.2 + 'px',
                      color: setting.rssTheme === 'black' ? '#ffbf5f' : 'inherit',
                    }"
                  >
                    {{ rssOwner }}
                  </div>
                </div>

                <div
                  v-if="item.title"
                  class="item-title scroolbar"
                  :style="{
                    fontSize: setting.display.titleFontSize + 'px',
                    lineHeight: setting.display.titleFontSize * 1.2 + 'px',
                    'margin-bottom': setting.display.spacing + 'px',
                  }"
                >
                  <div
                    v-if="item.pubDate"
                    class="item-date"
                    :style="{
                      fontSize:
                        (setting.display.titleFontSize > 16
                          ? setting.display.titleFontSize * 0.6
                          : 12) + 'px',
                      color: setting.rssTheme === 'black' ? '#ffbf5f' : 'inherit',
                    }"
                  >
                    {{ item.pubDate | relativeDay }}
                  </div>
                  {{ item.title }}
                </div>

                <div class="description-container scrollbar">
                  <div
                    v-if="setting.display.content"
                    class="item-description"
                    :style="{
                      fontSize: setting.display.contentSize + 'px',
                      lineHeight: setting.display.contentSize * 1.2 + 'px',
                    }"
                  >
                    {{ rssContent }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </transition-group>
    </template>

    <div v-else class="no-rss">
      <img src="@/assets/img/rss/rss_invalid.svg" class="rss-invalid" />
    </div>
  </div>
</template>

<script>
  // eslint-disable-next-line
  import moment from 'moment';
  import localforage from 'localforage';

  import { apiGetRss } from '@/api/rss';
  import { cacheData } from '@/helpers/caching';

  export default {
    name: 'RssViewer',

    emits: ['saveData'],

    props: {
      setting: {
        type: Object,
        default: () => ({}),
      },
    },

    filters: {
      relativeDay(value) {
        return moment(value).fromNow();
      },
      stripTags(description) {
        return description ? description.replace(/(<([^>]+)>)/gi, '') : '';
      },
    },

    data() {
      return {
        CAROUSEL_TRANSITION_INTERVAL: 10 * 1000, // 10 secs,
        rssRequestStatus: null,
        rssRequestError: null,
        rendering: true,

        rssUpdateEvery: 30, //mins
        rssUpdateInterval: null,
        currentIndex: 0,
      };
    },

    mounted() {
      if (!this.rssFeed) this.loadRSSFeed();
    },

    beforeDestroy() {
      if (this.rssUpdateInterval) {
        clearInterval(this.rssUpdateInterval);
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
    },

    computed: {
      theme() {
        if (this.setting.rssTheme === 'empty') {
          return '';
        }

        return this.setting.rssTheme === 'white' ? 'light-theme' : 'dark-theme';
      },

      duration() {
        if (this.setting.rssDuration > 0) {
          return this.setting.rssDuration * 1000;
        } else {
          return this.CAROUSEL_TRANSITION_INTERVAL;
        }
      },

      rssOwner() {
        return this.rssFeed && this.rssFeed.title ? this.rssFeed.title.split('|')[0] : 'News';
      },

      rssContent() {
        let currentDescription =
          this.rssFeed && this.rssFeed.items[this.currentIndex]
            ? this.rssFeed.items[this.currentIndex].description || ''
            : '';

        const isToolong =
          this.setting.display.contentLength > 0 &&
          currentDescription.length > this.setting.display.contentLength;

        if (isToolong) {
          currentDescription = currentDescription.slice(0, this.setting.display.contentLength);
        }

        return isToolong ? currentDescription + '...' : currentDescription;
      },

      rssFeed() {
        return this.$store.state.player.appsData[this.setting.appId]
          ? this.$store.state.player.appsData[this.setting.appId].data
          : null;
      },
    },

    methods: {
      startSlide() {
        this.currentIndex = 0;

        if (this.timer) clearInterval(this.timer);

        this.timer = setInterval(this.nextPage, this.duration);
      },

      nextPage() {
        if (!this.rssFeed) return;

        this.currentIndex =
          this.currentIndex < this.rssFeed.items.length - 1 ? this.currentIndex + 1 : 0;
      },

      stripTags(desc) {
        return desc.replace(/(<([^>]+)>)/gi, '');
      },

      async loadRSSFeed() {
        try {
          this.rssRequestStatus = 'loading';
          const response = await apiGetRss(this.setting.rssLink);

          if (!response) {
            const rssData = await localforage.getItem(`rss-${this.rssLink}`);

            if (!rssData) {
              throw new Error(`RSS cache fallback failed. Link: ${this.rssLink}`);
            }

            this.$emit('saveData', rssData);
            this.rssRequestStatus = 'success';

            return;
          }

          const { data } = response;
          const rssLinkShouldHaveFeedData = !data.title && data.items.length === 0;

          if (rssLinkShouldHaveFeedData) {
            this.rssRequestStatus = 'error';
            this.rssRequestError = "'" + this.setting.rssLink + "'" + 'is not a valid RSS URL';
          } else {
            this.rssRequestStatus = 'success';
            this.$emit('saveData', data);
            cacheData(`rss-${this.rssLink}`, data);
          }

          if (this.rssUpdateInterval) {
            clearInterval(this.rssUpdateInterval);
          }

          this.rssUpdateInterval = setInterval(this.loadRSSFeed, this.rssUpdateEvery * 1000 * 60);

          this.startSlide();
        } catch (error) {
          this.rssRequestStatus = 'error';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rss {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    .rss-slides {
      position: absolute;
      width: 100%;
      height: 100%;

      &.white {
        background-color: white;
        color: black;
      }

      &.black {
        background-color: black;
        color: white;
      }

      .slide-container {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;

        display: flex;
      }

      .item-image {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .item-body {
        display: flex;
        flex-direction: column;
        padding: 5% 16px;
        text-overflow: ellipsis;

        .item-header {
          display: flex;
          align-items: center;

          .header-title {
            font-size: 16px;
            font-weight: 500;
            color: #6a6b6a;
          }

          .rss-icon-container {
            width: 20%;
            max-width: 60px;
            min-width: 30px;
            padding: 8px;
            margin-right: 12px;
          }

          .rss-icon {
            width: 100%;
            border-radius: 20%;
            height: auto;
          }
        }

        .item-title {
          font-size: 24px;
          font-weight: 500;
          line-height: 30px;
        }

        .item-date {
          font-size: 12px;
          font-weight: 500;
          color: #6a6b6a;
        }

        .item-description {
          width: 100%;
          height: 100%;
        }

        .description-container {
          display: flex;
          flex-grow: 1;
          overflow-x: hidden;
          overflow-y: auto;

          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          -ms-text-overflow: ellipsis;
          overflow-wrap: break-word;
        }
      }
    }

    .rss-invalid {
      width: 100%;
    }

    .rss-loading {
      width: 30%;
      height: 30%;
      max-width: 50px;
      max-height: 50px;
    }

    .rss-error {
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
</style>
