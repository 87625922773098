import Vue from 'vue';
import localforage from 'localforage';

import { arrayToObjectWithIdsAsKeys, getWidgetItemType } from '@/helpers/utils';
import { cacheData } from '@/helpers/caching';
import {
  RESET_PLAYER,
  PLAYER_LOAD_LAYOUT,
  PLAYER_LOAD_LAYOUT_CHILDREN,
  PLAYER_LOAD_LAYOUT_ERROR,
  USE_PLAYER_CACHED_DATA,
  PLAYER_SET_LAYOUT,
  SET_LAYOUT_CHILDREN,
  SET_PLAYLIST_CHILDREN,
  SET_TEMPLATE_ITEMS,
  SET_PLAYLIST_TEMPLATE_ITEMS,
  SET_LOCAL_TEMPLATE_ITEMS,
  SET_APP_DATA,
  SET_PLAYER_ROTATION,
  PLAYER_SET_LAYOUT_ORIENTATION,
  OPEN_PLAYER_MODAL,
  CLOSE_PLAYER_MODAL,
} from '@/store/actions/player';
import { LAYOUT_RESOLUTIONS, WIDGET_TYPES } from '@/models/layoutDesigner';

import { apiGetScreenLayout, apiGetScreenLayoutChildren } from '@/api/layouts';

const state = {
  loadingLayout: false,
  layout: null,
  widgets: {},
  playlistsItems: {},
  templateItems: {},
  templateOnPlaylistItems: {},
  localTemplatesItems: {},
  isPlayerHorizontal: false,
  screenId: null,
  showPlayerModal: false,
  modalContent: null,
  appsData: {},
};

const getters = {
  getPlayerRotation: (state) => (state.isPlayerHorizontal ? 'horizontal' : 'vertical'),

  getPlaylistItems: (state) => (assocId) => {
    const playlistItems = state.playlistsItems[assocId];

    if (!playlistItems) return null;

    const sortedPlaylistItems = Object.values(playlistItems).sort(
      (item1, item2) => item1.item_priority - item2.item_priority,
    );

    return sortedPlaylistItems;
  },
};

const actions = {
  [PLAYER_LOAD_LAYOUT]: async ({ commit, dispatch }, { screenId }) => {
    try {
      commit(RESET_PLAYER, { screenId });
      commit(PLAYER_LOAD_LAYOUT);

      const response = await apiGetScreenLayout(screenId);

      const layout = response
        ? await cacheData(`screen-${screenId}-layout`, response.data)
        : await localforage.getItem(`screen-${screenId}-layout`);

      if (!layout) throw new Error('Unable to load screen');

      commit(PLAYER_SET_LAYOUT, layout);
      commit(PLAYER_SET_LAYOUT_ORIENTATION, layout.settings.isHorizontal);

      // Forcing screen resolution on Screen
      const { aspectRatio, resolution, isHorizontal } = layout.settings;
      const { width, height } = LAYOUT_RESOLUTIONS.resolutions[aspectRatio][resolution];

      document.getElementById('viewport').setAttribute(
        'content',
        `user-scalable=no,
        height=${isHorizontal ? height : width},
        width=${isHorizontal ? width : height}`,
      );

      await dispatch(!response ? USE_PLAYER_CACHED_DATA : PLAYER_LOAD_LAYOUT_CHILDREN, {
        layout,
        screenId,
      });
    } catch (error) {
      commit(PLAYER_LOAD_LAYOUT_ERROR, error);
      console.log('error: ', error);
    }
  },

  async [USE_PLAYER_CACHED_DATA]({ commit, state, dispatch }, { layout, screenId }) {
    state.layout = layout;

    const widgets = await localforage.getItem(`layoutChildren-${screenId}`);

    if (widgets) {
      commit(SET_LAYOUT_CHILDREN, { layoutChildren: widgets, screenId, cache: false });
    } else {
      console.log("No Screen's children found on cache");

      // Try to get children if not cached
      dispatch(PLAYER_LOAD_LAYOUT_CHILDREN, {
        layout,
        screenId,
      });
    }
  },

  [PLAYER_LOAD_LAYOUT_CHILDREN]: async ({ commit }, { screenId }) => {
    try {
      const response = await apiGetScreenLayoutChildren(screenId);

      const widgets = response
        ? await cacheData(`layoutChildren-${screenId}`, response.data)
        : await localforage.getItem(`layoutChildren-${screenId}`);

      if (!widgets) throw new Error('Unable to load screen');

      const formatedWidgets = widgets.map((widget) => {
        const isAnApp = widget.object.item_type ? widget.object.item_type.includes('app') : false;

        let itemType = getWidgetItemType(widget);

        if (isAnApp) {
          if (widget.object.config && widget.object.config.override) {
            delete widget.object.config.override;
          }

          if (widget.object.override && widget.object.override.config.override) {
            delete widget.object.override.config.override;
          }
        }

        let layouObject = isAnApp
          ? Object.assign({}, widget.object, widget.object.config)
          : widget.object;

        if (widget.object.override) {
          if (
            itemType === WIDGET_TYPES.TEXT ||
            itemType === WIDGET_TYPES.LAYER ||
            itemType === WIDGET_TYPES.RICH_TEXT ||
            (itemType === WIDGET_TYPES.APP && layouObject.item_type.includes('table'))
          ) {
            layouObject = { ...layouObject, ...layouObject.override.config };
          }
        }

        return Object.assign({}, widget, {
          assoc_id: widget.assoc_id,
          object: Object.assign({}, layouObject, { type: itemType }),
          itemType,
        });
      });

      let fileCount = 0;
      const files = {};

      formatedWidgets.forEach((widget) => {
        if (widget.object.type === 'video') {
          fileCount++;
          files[widget.object.item_id] = widget.position.data.url;
        }
      });

      if (window.isWebOS === true && fileCount > 0) {
        // TODO: implement disk cache for images and videos in WebOS. This functionality is essential for reducing data traffic out of AWS
        console.warn('Disk cache for WebOS is not implemented yet.');

        commit(SET_LAYOUT_CHILDREN, {
          layoutChildren: arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'),
          screenId,
        });
      } else if (window.isAndroid === true && fileCount > 0) {
        const successCb = (res) => {
          const downloadedFiles = res.files;
          const cachedWidgets = formatedWidgets.map((widget) => {
            if (widget.object.type === 'video') {
              if (downloadedFiles[widget.object.item_id]) {
                widget.position.data.url = downloadedFiles[widget.object.item_id];
              }
            }
            return widget;
          });
          commit(SET_LAYOUT_CHILDREN, {
            layoutChildren: arrayToObjectWithIdsAsKeys(cachedWidgets, 'assoc_id'),
            screenId,
          });
        };

        const failureCb = (err) => {
          commit(SET_LAYOUT_CHILDREN, {
            layoutChildren: arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'),
            screenId,
          });
        };

        // Set result call back for android
        window.finishDownload = (res) => {
          let result = JSON.parse(res);
          if (result.error) {
            failureCb(result.error);
          } else {
            successCb(result);
          }
        };

        // Request download files on android
        if (window.downloadFiles) window.downloadFiles(files);
      } else {
        commit(SET_LAYOUT_CHILDREN, {
          layoutChildren: arrayToObjectWithIdsAsKeys(formatedWidgets, 'assoc_id'),
          screenId,
        });
      }

      return formatedWidgets;
    } catch (error) {
      commit(PLAYER_LOAD_LAYOUT_ERROR, error);
      console.error(error);
      return false;
    }
  },
};

const mutations = {
  [PLAYER_LOAD_LAYOUT]: (state) => {
    state.loadingLayout = true;
  },

  [PLAYER_SET_LAYOUT]: (state, payload) => {
    state.layout = payload;
  },

  [PLAYER_LOAD_LAYOUT_ERROR]: (state, error) => {
    state.loadingLayout = false;
  },

  [RESET_PLAYER]: (state, { screenId = null }) => {
    state.layout = null;
    state.widgets = {};
    state.playlistsItems = {};
    state.templateItems = {};
    state.templateOnPlaylistItems = {};
    state.localTemplatesItems = {};
    state.appsData = {};

    state.screenId = screenId;
  },

  [SET_LAYOUT_CHILDREN]: (state, { layoutChildren, screenId, cache = true }) => {
    state.loadingLayout = false;

    if (cache) cacheData(`layoutChildren-${screenId}`, layoutChildren);

    Vue.set(state, 'widgets', layoutChildren);
  },

  [OPEN_PLAYER_MODAL]: (state, modalContent) => {
    state.showPlayerModal = true;
    state.modalContent = modalContent;
  },

  [CLOSE_PLAYER_MODAL]: (state) => {
    state.showPlayerModal = false;
    state.modalContent = null;
  },

  [SET_PLAYLIST_CHILDREN](state, { assocId, playlistItems = {} }) {
    Vue.set(state.playlistsItems, assocId, playlistItems);
  },

  [SET_TEMPLATE_ITEMS](state, { key, widgets }) {
    Vue.set(state.templateItems, key, widgets);
  },

  [SET_PLAYLIST_TEMPLATE_ITEMS](state, { key, widgets }) {
    Vue.set(state.templateOnPlaylistItems, key, widgets);
  },

  [SET_LOCAL_TEMPLATE_ITEMS](state, { playlistId, key, widgets }) {
    if (!state.localTemplatesItems[playlistId]) Vue.set(state.localTemplatesItems, playlistId, {});

    Vue.set(state.localTemplatesItems[playlistId], key, widgets);
  },

  [SET_APP_DATA](state, { appId, appData }) {
    Vue.set(state.appsData, appId, appData);
  },

  [SET_PLAYER_ROTATION]: (state, value = false) => {
    if (state.layout) {
      const layout = JSON.parse(JSON.stringify(state.layout));
      layout.settings.isHorizontal = value;
      Vue.set(state, 'layout', layout);
    }
    state.isPlayerHorizontal = value;
  },

  [PLAYER_SET_LAYOUT_ORIENTATION]: (state, value) => {
    state.isPlayerHorizontal = value;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
