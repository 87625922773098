import { apiGetScreenLayoutMediaAsset } from '@/api/layouts';
import { SCREEN_ID_ENUM } from '@/constants';
import WebOSStorageManager, { byteToMB } from '@/utils/internalFileStorage/webOSStorageManager';
import {
  PICFLOW_INTERNAL_STORAGE_QUOTA_IN_PERCENT,
  PICFLOW_MAX_NUMBER_OF_FILES_IN_INTERNAL_STORAGE,
  WEBOS_FILE_TYPE,
  WEBOS_INTERNAL_RELATIVE_PATH,
} from '@/constants/webOS';
import pLimit from 'p-limit';
import { getEncodedURL } from '@/mixins/simple-type';
import config from '@/config';
import { FileDownloadHistoryStore } from '@/utils/internalFileStorage/fileDownloadHistoryStore';
import { InternalFileStore } from '@/utils/internalFileStorage/internalFileStore';
import {
  apiCreateScreenInternalStorage,
  apiGetScreenInternalStorage,
  apiUpdateScreenInternalStorage,
} from '@/api/screenInternalStorage';
import { SentryLogger } from '@/utils/sentryLogger';

export const MAX_ACTIVE_DOWNLOADS = 5;

export const FILE_SYNC_DOWNLOAD_STATUS = Object.freeze({
  Success: 'success',
  Failed: 'failed',
});

function convertURLToWebOSInternalURI(localUri) {
  return `${WEBOS_INTERNAL_RELATIVE_PATH}/${localUri}`;
}

export function getInternalStorageQuota(internalStorageSize) {
  return internalStorageSize * PICFLOW_INTERNAL_STORAGE_QUOTA_IN_PERCENT;
}

export function updateInternalFilesWithMediaAssetData(internalFiles, mediaAssets) {
  const filesToUpdate = internalFiles.filter((file) => file.type !== WEBOS_FILE_TYPE.Folder);

  var numberOfFilesThatIsUsedByScreen = 0;
  var internalFilesThatIsUsedByScreenInMB = 0;

  for (let i = 0; i < filesToUpdate.length; i++) {
    const file = filesToUpdate[i];
    const isUsedByScreen = mediaAssets.has(file.localFileUrl);

    var asset = null;
    if (isUsedByScreen) {
      asset = mediaAssets.get(file.localFileUrl);

      ++numberOfFilesThatIsUsedByScreen;
      internalFilesThatIsUsedByScreenInMB += byteToMB(file.size);
    }

    filesToUpdate[i] = {
      ...file,
      itemId: asset?.itemId,
      itemSize: asset?.itemSize,
      itemUrl: asset?.itemUrl,
      itemLocalUrl: asset?.itemLocalUrl,
      isUsedByScreen: isUsedByScreen,
      needsToBeRemoved: false,
    };
  }

  return {
    numberOfFilesThatIsUsedByScreen,
    internalFilesThatIsUsedByScreenInMB,
    internalFiles: filesToUpdate,
  };
}

export function markFilesForRemovalToFitStorageQuota(
  internalFiles,
  usedInMB,
  totalNumberOfInternalFiles,
  mediaAssets,
  internalStorageQuotaInMB,
) {
  var mbInUsed = usedInMB;
  var numberOfInternalFiles = totalNumberOfInternalFiles;

  const filesUsedByScreen = [];
  const filesNotUsedByScreen = internalFiles.reduce((array, file) => {
    const isUsedByScreen = mediaAssets.has(file.localFileUrl);

    if (isUsedByScreen) {
      filesUsedByScreen.push(file);
      return array;
    }

    array.push(file);
    return array;
  }, []);

  // sort dates by ascending order
  filesNotUsedByScreen.sort((a, b) => {
    return new Date(a.metadata.modifiedAt) - new Date(b.metadata.modifiedAt);
  });

  filesUsedByScreen.sort((a, b) => {
    return new Date(a.metadata.modifiedAt) - new Date(b.metadata.modifiedAt);
  });

  const sortedInternalFiles = filesNotUsedByScreen.concat(filesUsedByScreen);

  for (let i = 0; i < sortedInternalFiles.length; i++) {
    if (
      mbInUsed <= internalStorageQuotaInMB &&
      numberOfInternalFiles <= PICFLOW_MAX_NUMBER_OF_FILES_IN_INTERNAL_STORAGE
    ) {
      break;
    }

    const file = sortedInternalFiles[i];
    file.needsToBeRemoved = true;

    mbInUsed = mbInUsed - byteToMB(file.size);
    --numberOfInternalFiles;
  }

  return sortedInternalFiles;
}

export function isExceedingInternalStorageQuota(
  internalStorageQuotaInMB,
  usedInMB,
  internalFilesThatIsUsedByScreenInMB,
  mediaAssetTotalSizeInMB,
) {
  return (
    internalStorageQuotaInMB <
    usedInMB - internalFilesThatIsUsedByScreenInMB + mediaAssetTotalSizeInMB
  );
}

export function isExceedingMaxNumberOfInternalFiles(
  totalNumberOfInternalFiles,
  numberOfFilesThatIsUsedByScreen,
  numberOfMediaAssets,
) {
  return (
    PICFLOW_MAX_NUMBER_OF_FILES_IN_INTERNAL_STORAGE <
    totalNumberOfInternalFiles - numberOfFilesThatIsUsedByScreen + numberOfMediaAssets
  );
}

export class FileSynchronizationManager {
  static isSynchronizingFiles = false;

  static async sendFileSyncInfo() {
    const screenId = localStorage.getItem(SCREEN_ID_ENUM);
    const screenInternalStorages = await apiGetScreenInternalStorage(screenId);

    const fileDownloadHistory = await FileDownloadHistoryStore.getStore();
    const internalFiles = await InternalFileStore.getStore();

    if (screenInternalStorages.length === 0) {
      await apiCreateScreenInternalStorage(screenId, {
        screenId: screenId,
        fileDownloadHistory: fileDownloadHistory,
        internalFiles: internalFiles,
      });
    } else {
      const screenInternalStorage = screenInternalStorages[0];

      screenInternalStorage.fileDownloadHistory = fileDownloadHistory;
      screenInternalStorage.internalFiles = internalFiles;

      await apiUpdateScreenInternalStorage(screenId, screenInternalStorage);
    }
  }

  static async getFilesToSync() {
    const screenId = localStorage.getItem(SCREEN_ID_ENUM);
    const data = await apiGetScreenLayoutMediaAsset(screenId);

    const mediaAssetsAsMap = new Map(
      data.mediaAssets.map((asset) => {
        const localFileUrl = convertURLToWebOSInternalURI(asset.itemLocalUrl);
        return [localFileUrl, { ...asset, itemLocalUrl: localFileUrl }];
      }),
    );

    return { ...data, mediaAssets: mediaAssetsAsMap };
  }

  static async getFilesInInternalFileSystem(organisationId) {
    var data = null;

    try {
      const folderPath = `${WEBOS_INTERNAL_RELATIVE_PATH}/${organisationId}`;
      const { exists: folderPathExists } = await WebOSStorageManager.exists(folderPath);

      if (!folderPathExists) {
        await WebOSStorageManager.mkdir(folderPath);
      }

      const fileObject = await WebOSStorageManager.listFilesWithMetadata(folderPath);
      const storageInfo = await WebOSStorageManager.getStorageInfo();

      data = {
        freeInMB: storageInfo.freeInMB,
        totalInMB: storageInfo.totalInMB,
        usedInMB: storageInfo.usedInMB,
        fileObject: fileObject,
      };
    } catch (error) {
      console.error('Failed to get internal files');
      throw error;
    }

    return data;
  }

  static async preprocessFilesForDownload(internalFilesData, filesToSyncData) {
    const mediaAssets = Array.from(filesToSyncData.mediaAssets.values());

    const { numberOfFilesThatIsUsedByScreen, internalFilesThatIsUsedByScreenInMB } =
      updateInternalFilesWithMediaAssetData(
        Array.from(internalFilesData.fileObject.files.values()),
        filesToSyncData.mediaAssets,
      );

    const internalStorageQuotaInMB = getInternalStorageQuota(internalFilesData.totalInMB);

    var shouldDownload = true;
    if (
      isExceedingInternalStorageQuota(
        internalStorageQuotaInMB,
        internalFilesData.usedInMB,
        internalFilesThatIsUsedByScreenInMB,
        filesToSyncData.totalSizeInMB,
      )
    ) {
      shouldDownload = false;
      SentryLogger.error(
        `Wont sync: The total size of media assets is ${filesToSyncData.totalSizeInMB} MB, exceeding the storage quota of ${internalStorageQuotaInMB} MB`,
      );
    } else if (
      isExceedingMaxNumberOfInternalFiles(
        internalFilesData.fileObject.files.size,
        numberOfFilesThatIsUsedByScreen,
        filesToSyncData.mediaAssets.size,
      )
    ) {
      shouldDownload = false;
      SentryLogger.error(
        `Wont sync: The total files of media assets is ${internalFilesData.fileObject.files.size}, exceeding the max number of internal files of ${PICFLOW_MAX_NUMBER_OF_FILES_IN_INTERNAL_STORAGE}`,
      );
    }

    for (let i = 0; i < mediaAssets.length; i++) {
      const asset = mediaAssets[i];
      const existsLocally = internalFilesData.fileObject.files.has(asset.itemLocalUrl);

      mediaAssets[i] = {
        ...asset,
        existsLocally: existsLocally,
        needsToBeDownloaded: shouldDownload && !existsLocally,
        status: FILE_SYNC_DOWNLOAD_STATUS.Failed,
        retries: 0,
      };
    }

    return mediaAssets;
  }

  static async preprocessInternalFilesForRemoval(internalFilesData, filesToSyncData) {
    const { internalFiles, numberOfFilesThatIsUsedByScreen, internalFilesThatIsUsedByScreenInMB } =
      updateInternalFilesWithMediaAssetData(
        Array.from(internalFilesData.fileObject.files.values()),
        filesToSyncData.mediaAssets,
      );

    const internalStorageQuotaInMB = getInternalStorageQuota(internalFilesData.totalInMB);

    // FIFO based file removal based on device storage quota
    if (
      isExceedingInternalStorageQuota(
        internalStorageQuotaInMB,
        internalFilesData.usedInMB,
        internalFilesThatIsUsedByScreenInMB,
        filesToSyncData.totalSizeInMB,
      ) ||
      isExceedingMaxNumberOfInternalFiles(
        internalFilesData.fileObject.files.size,
        numberOfFilesThatIsUsedByScreen,
        filesToSyncData.mediaAssets.size,
      )
    ) {
      return markFilesForRemovalToFitStorageQuota(
        internalFiles,
        internalFilesData.usedInMB,
        internalFilesData.fileObject.files.size,
        filesToSyncData.mediaAssets,
        internalStorageQuotaInMB,
      );
    }

    return internalFiles;
  }

  static async preprocessTempInternalFilesForRemoval(internalFilesData, filesToSyncData) {
    const assets = Array.from(filesToSyncData.mediaAssets.values());

    const transformedTempInternalFiles = Array.from(
      internalFilesData.fileObject.files.values(),
    ).filter((file) => file.type !== WEBOS_FILE_TYPE.Folder);

    for (let i = 0; i < transformedTempInternalFiles.length; i++) {
      const file = transformedTempInternalFiles[i];
      const needsToBeRemoved = assets.some(
        (asset) =>
          asset.itemLocalUrl !== file.localFileUrl &&
          WebOSStorageManager.isTemporaryFile(file.localFileUrl),
      );

      transformedTempInternalFiles[i] = {
        ...file,
        needsToBeRemoved: needsToBeRemoved,
      };
    }

    return transformedTempInternalFiles;
  }

  static async handleFileEviction(internalFilesData, filesToSyncData) {
    // cleanup failed incomplete file downloads
    const tempInternalFilesToRemove = (
      await this.preprocessTempInternalFilesForRemoval(internalFilesData, filesToSyncData)
    ).filter((file) => file.needsToBeRemoved === true);

    // we want to do remove files synchronously
    for (let i = 0; i < tempInternalFilesToRemove.length; i++) {
      const tempFile = tempInternalFilesToRemove[i];
      try {
        await WebOSStorageManager.removeFile(tempFile.localFileUrl);
      } catch (error) {
        SentryLogger.error('Failed to removed temp file from internal storage', error);
      }
    }

    const internalFilesToRemove = (
      await this.preprocessInternalFilesForRemoval(internalFilesData, filesToSyncData)
    ).filter((file) => file.needsToBeRemoved === true);

    // we want to do remove files synchronously
    for (let i = 0; i < internalFilesToRemove.length; i++) {
      const file = internalFilesToRemove[i];
      try {
        await WebOSStorageManager.removeFile(file.localFileUrl);
      } catch (error) {
        SentryLogger.error('Failed to removed file from internal storage', error);
      }
    }
  }

  static async updateLocalForage(filesToSyncData) {
    const internalFilesDataAfterSync = await this.getFilesInInternalFileSystem(
      filesToSyncData.organisationId,
    );
    const internalFilesAfterSync = await this.preprocessInternalFilesForRemoval(
      internalFilesDataAfterSync,
      filesToSyncData,
    );

    for (let i = 0; i < internalFilesAfterSync.length; i++) {
      const internalFile = internalFilesAfterSync[i];
      await InternalFileStore.setItemOrUpdate(internalFile.localFileUrl, internalFile);
    }

    const internalFileStore = await InternalFileStore.getStore();
    const fileDownloadHistoryStore = await FileDownloadHistoryStore.getStore();

    for (let i = 0; i < internalFileStore.length; i++) {
      const data = internalFileStore[i];

      if (!internalFilesAfterSync.some((file) => file.localFileUrl === data.localFileUrl)) {
        await InternalFileStore.removeItem(data.localFileUrl);
      }
    }

    for (let i = 0; i < fileDownloadHistoryStore.length; i++) {
      const data = fileDownloadHistoryStore[i];

      if (!internalFilesAfterSync.some((file) => file.localFileUrl === data.itemLocalUrl)) {
        await FileDownloadHistoryStore.removeItem(data.itemLocalUrl);
      }
    }
  }

  static async runFileSynchronizationOnce() {
    if (this.isSynchronizingFiles === true) {
      return false;
    }

    console.log(`Start running sync: ${new Date().toISOString()}`);
    this.isSynchronizingFiles = true;

    var allFilesSynced = true;
    try {
      const filesToSyncData = await this.getFilesToSync();

      await this.handleFileEviction(
        await this.getFilesInInternalFileSystem(filesToSyncData.organisationId),
        filesToSyncData,
      );

      const filesToSync = await this.preprocessFilesForDownload(
        await this.getFilesInInternalFileSystem(filesToSyncData.organisationId),
        filesToSyncData,
      );
      const limit = pLimit(MAX_ACTIVE_DOWNLOADS);

      const filesToDownload = filesToSync
        .filter((asset) => asset.needsToBeDownloaded === true)
        .map((asset) =>
          limit(async () => {
            try {
              if (!asset.fontName) {
                await WebOSStorageManager.customFileDownload(
                  getEncodedURL(config.baseUrl, asset.itemUrl),
                  asset.itemLocalUrl,
                );
              } else {
                // font uses whole urls
                await WebOSStorageManager.customFileDownload(asset.itemUrl, asset.itemLocalUrl);
              }

              asset.status = FILE_SYNC_DOWNLOAD_STATUS.Success;
              await FileDownloadHistoryStore.setItemOrUpdate(asset.itemLocalUrl, asset);

              console.log('Downloaded:', asset.itemUrl);
            } catch (error) {
              allFilesSynced = false;

              asset.status = FILE_SYNC_DOWNLOAD_STATUS.Failed;
              await FileDownloadHistoryStore.setItemOrUpdate(asset.itemLocalUrl, asset);

              SentryLogger.error(
                `Failed to download file ${asset.itemUrl} to internal storage`,
                error,
              );
            }
          }),
        );

      await Promise.allSettled(filesToDownload);

      // update localforage of what file exists in disk after sync
      await this.updateLocalForage(filesToSyncData);
      await this.sendFileSyncInfo();
    } catch (error) {
      SentryLogger.error('Failed running file synchronization', error);
    }

    console.log(`Finished running sync: ${new Date().toISOString()}`);
    this.isSynchronizingFiles = false;

    return allFilesSynced;
  }

  static async removeAllInternalFiles() {
    await FileDownloadHistoryStore.clear();
    await InternalFileStore.clear();
    await WebOSStorageManager.removeAll('internal');
  }
}
