<template>
  <div class="widget-item" :style="style">
    <PlaylistPlayer
      :style="{ position: 'relative' }"
      :playlist="
        Object.assign(widget.object, {
          imageMode: widget.object.imageMode || widget.position.imageMode,
        })
      "
      :assocId="widget.assoc_id"
    />
  </div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';

  import PlaylistPlayer from '@/components/player/PlaylistPlayer.vue';

  export default {
    name: 'PlaylistWidget',

    mixins: [playerMixins, simpleTypeMixin],

    components: {
      PlaylistPlayer,
    },

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    computed: {
      style() {
        const style = this.defaultStyling(this.widget);

        return style;
      },
    },
  };
</script>

<style scoped lang="scss">
  .widget-item {
    position: absolute;
  }
</style>
