import { ScreenCode, ScreenToken } from '@/types/api/login';
import config from '../config';
import http from '@/utils/http';
import { REFRESH_TOKEN_ENUM, UNIQUE_SCREEN_CODE } from '@/constants';

export async function generateScreenCode(): Promise<ScreenCode> {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  const response = await http.get<ScreenCode>(
    `${config.apiUrl}userapi/screen-code?uniqueScreenCode=${screenCode}`,
  );
  return response.data;
}

export async function checkValidation(): Promise<ScreenToken> {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  const response = await http.get<ScreenToken>(
    `${config.apiUrl}userapi/screen-code-validation?uniqueScreenCode=${screenCode}`,
  );
  return response.data;
}

export async function validateScreenCode(code: string): Promise<ScreenToken> {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  const response = await http.post<ScreenToken>(`${config.apiUrl}userapi/screen-code`, {
    code,
    uniqueScreenCode: screenCode,
  });
  return response.data;
}

export async function refreshAuthToken(): Promise<ScreenToken> {
  const screenCode = localStorage.getItem(UNIQUE_SCREEN_CODE);
  const response = await http.post<ScreenToken>(`${config.apiUrl}userapi/refresh-token`, {
    refreshToken: localStorage.getItem(REFRESH_TOKEN_ENUM),
    uniqueScreenCode: screenCode,
  });
  return response.data;
}
