import config from '../config';
import http from '@/utils/http';

export async function apiGetRss(rssUrl) {
  try {
    return await http.get(`${config.apiUrl}userapi/app/demo/rss?url=${rssUrl}`);
  } catch (error) {
    console.log(`RSS API request failed: ${error?.message}. Url: ${rssUrl}`);

    return null;
  }
}
