export async function preLoadFont(fontUrl: string, fontFormat: string, fontName: string) {
  return new Promise((resolve, reject) => {
    const id = `preload-${fontName}`;
    if (document.getElementById(id)) {
      resolve(true);
      return;
    }

    const link = document.createElement('link');

    link.id = id;
    link.rel = 'preload';
    link.href = fontUrl;
    link.as = 'font';
    link.type = `font/${fontFormat}`;
    link.crossOrigin = 'anonymous';

    link.onload = () => resolve(true);
    link.onerror = () => reject(`Failed to preload font: ${fontUrl}`);

    document.head.appendChild(link);
  });
}

export async function createFontFaceStyle(fontUrl: string, fontName: string) {
  return new Promise((resolve, reject) => {
    const id = `font-face-${fontName}`;
    if (document.getElementById(id)) {
      resolve(true);
      return;
    }

    const style = document.createElement('style');

    style.id = id;
    style.appendChild(
      document.createTextNode(
        `@font-face {
              font-family: ${fontName};
              src: local(${fontName}),
                   url(${fontUrl});
            }`,
      ),
    );

    style.onload = () => resolve(true);
    style.onerror = () => reject(`Failed to create font face: ${fontUrl}`);

    document.head.appendChild(style);
  });
}
