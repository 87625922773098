import config from '../config';
import http from '@/utils/http';

export const apiWeatherCurrentConditions = async (location, language = 'en', type) => {
  try {
    const url = new URL('userapi/app/weather/get-weather', config.apiUrl);

    return await http.post(
      url.toString(),
      {
        location,
        language,
        type,
      },
      { 'axios-retry': { retries: 0 } },
    );
  } catch (error) {
    console.log(`Weather API request failed: ${error?.message}. Type: ${type}`);

    return null;
  }
};
