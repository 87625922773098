<template>
  <PdfView
    :pdfImages="widget.object.pdf_content.thumbnail_url"
    :pageDuration="widget.position.data.pageDuration"
    :imageMode="widget.position.data.imageMode"
    :style="style"
    position="absolute"
  />
</template>

<script>
  import templateWidget from '@/components/widgets/templates/mixins/templateWidget';
  import PdfView from '@/components/content/PdfView.vue';

  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'PdfWidget',

    mixins: [templateWidget, simpleTypeMixin],

    components: {
      PdfView,
    },

    props: {
      widget: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      style() {
        return Object.assign({}, this.defaultStyling(this.widget), {
          position: 'absolute',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pdf-viewver {
    overflow: hidden;
  }
</style>
