import config from '../config';
import http from '@/utils/http';

export async function apiGetScreenTemplateChildren(templateId, screenId, playlistId) {
  try {
    const endpoint = `${config.apiUrl}userapi/screens/${screenId}/template/${templateId}/children`;
    const query = playlistId ? `?playlist_id=${playlistId}` : '';

    return await http.get(`${endpoint}${query}`);
  } catch (error) {
    console.log(
      `Template's children API failed: ${error?.message}. Local template: ${templateId}. ${
        playlistId ? `Playlist: ${playlistId}` : ''
      }`,
    );

    return null;
  }
}
