import moment from 'moment';
import config from '@/config';

import { WIDGET_TYPES } from '@/models/layoutDesigner';
import { UNIQUE_SCREEN_CODE } from '@/constants';

export const getDeviceSpecificParam = () => {
  let param = `webOSVersion=true`;

  if (window.isAndroid) {
    param = 'android=true';
  } else if (window.isWeb) {
    param = 'isWeb=true';
  }

  return param;
};

export const getPositionData = (widget) => {
  const { width = 100, height = 100, x = 0, y = 0, zIndex = 0 } = widget;

  return {
    zIndex,
    width,
    height,
    x,
    y,
  };
};

export const arrayToObjectWithIdsAsKeys = (itemsArray, key = 'id') => {
  return itemsArray.reduce((object, item) => {
    object[item[key]] = item;
    return object;
  }, {});
};

export const getRBGAColor = (hexColor = '#FFFFFF', opacity = 1) => {
  var _hex = hexColor.replace('#', '');

  // handle backward compatibility for older widgets that uses hex3
  _hex = _hex.length === 3 ? _hex.replace(/./g, '$&$&') : _hex;

  const red = parseInt(_hex.substring(0, 2), 16);
  const green = parseInt(_hex.substring(2, 4), 16);
  const blue = parseInt(_hex.substring(4, 6), 16);

  return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + opacity + ')';
};

export function hex8ToRgba(hex8) {
  const _isHex8 = isHex8(hex8);

  const _hex8 = hex8.replace('#', '');

  const red = parseInt(_hex8.substring(0, 2), 16);
  const green = parseInt(_hex8.substring(2, 4), 16);
  const blue = parseInt(_hex8.substring(4, 6), 16);
  const alpha = _isHex8 ? parseInt(_hex8.substring(6, 8), 16) / 255 : 1;

  return 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + alpha + ')';
}

export function isHex8(hex) {
  return hex[0] === '#' && hex.length === 9;
}

export const getWidgetItemType = (widget) => {
  let itemType;

  if (widget.object.playlist_id) {
    itemType = WIDGET_TYPES.PLAYLIST;
  } else if (widget.type === WIDGET_TYPES.TEMPLATE) {
    itemType = WIDGET_TYPES.TEMPLATE;
  } else if (widget.object.config) {
    itemType = widget.object.config.type;
  } else if (widget.object) {
    if (widget.object.item_type && widget.object.item_type.includes('pdf')) {
      itemType = WIDGET_TYPES.PDF;
    } else if (widget.object.item_type && widget.object.item_type.includes('app')) {
      itemType = WIDGET_TYPES.APP;
    } else if (widget.object.item_type && widget.object.item_type.includes('video')) {
      itemType = WIDGET_TYPES.VIDEO;
    } else if (widget.object.item_type && widget.object.item_type.includes('audio')) {
      itemType = WIDGET_TYPES.AUDIO;
    } else {
      itemType = widget.object.type || WIDGET_TYPES.IMAGE;
    }
  } else {
    itemType = null;
  }

  return itemType;
};

export const formattedDate = (date, format = 'DD/MM/YYYY HH:mm') => {
  return moment(date).format(format);
};

export const formatTemplateWidgets = (widgets) => {
  return widgets.map((widget) => {
    const isAnApp = widget.object.item_type && widget.object.item_type.includes('app');

    const itemType = getWidgetItemType(widget);

    if (isAnApp) {
      if (widget.object.config && widget.object.config.override) {
        delete widget.object.config.override;
      }

      if (widget.object.override && widget.object.override.config.override) {
        delete widget.object.override.config.override;
      }
    }

    let layouObject = isAnApp
      ? { ...widget.object, config: undefined, ...widget.object.config }
      : widget.object;

    if (widget.object.override) {
      if (
        itemType === WIDGET_TYPES.TEXT ||
        itemType === WIDGET_TYPES.LAYER ||
        itemType === WIDGET_TYPES.RICH_TEXT ||
        itemType === WIDGET_TYPES.TENANTS ||
        (itemType === WIDGET_TYPES.APP && layouObject.item_type.includes('table'))
      ) {
        layouObject = { ...layouObject, ...layouObject.override.config };
      }
    }

    return {
      ...widget,
      assoc_id: widget.assoc_id,
      object: { ...layouObject, type: itemType },
      itemType,
    };
  });
};

export const formatTileWidgets = (tileWidgets) => {
  return tileWidgets.map((widget) => {
    const isAnApp = widget.object.item_type && widget.object.item_type.includes('app');

    const itemType = getWidgetItemType(widget);

    if (isAnApp) {
      if (widget.object.config && widget.object.config.override)
        delete widget.object.config.override;
      if (
        widget.object.override &&
        widget.object.override.config &&
        widget.object.override.config.override
      )
        delete widget.object.override.config.override;
    }

    let layouObject = isAnApp
      ? {
          ...widget.object,
          ...(widget.config || {}),
          ...(widget.config && widget.config.config ? widget.config.config : {}),
          config: undefined,
        }
      : widget.object;

    if (widget.object.override && widget.object.override.config.position) {
      widget.position = widget.object.override.config.position;
    }

    if (widget.object.override && widget.object.override.config.object) {
      layouObject = {
        ...layouObject,
        ...widget.object.override.config.object,
        override: { ...widget.object.override, config: widget.object.override.config.object },
      };
    }

    return {
      ...widget,
      config: undefined,
      assoc_id: widget.assoc_id,
      object: { ...layouObject, type: itemType },
      itemType,
    };
  });
};

export const getRSSAppSettings = (appSettings, appId) => {
  const displayDefaultOptions = {
    rssName: true,
    content: true,
    image: true,
    imageWidth: 25,
    imageMode: 'cover',
    feedNameSize: 16,
    titleFontSize: 30,
    contentSize: 20,
    contentLength: 200,
    spacing: 16,
    padding: 16,
  };

  return {
    rssLink: appSettings.link,
    rssDuration: parseInt(appSettings.display && appSettings.display.duration) || 10,
    rssTheme: appSettings.theme,
    display: appSettings.display || displayDefaultOptions,
    appId,
  };
};

export const getCloudfrontUrl = (key) => {
  let cleanedKey = key;
  if (key.indexOf(config.s3BaseUrl) != -1) {
    const regex = new RegExp(`${config.s3BaseUrl}`, 'i');
    cleanedKey = key.replace(regex, '');
  } else if (key.indexOf(config.alternateS3BaseUrl) != -1) {
    const regex = new RegExp(`${config.alternateS3BaseUrl}`, 'i');
    cleanedKey = key.replace(regex, '');
  }
  return `${config.baseUrl}/${cleanedKey}`;
};

export function divideArray(array, size = 1) {
  return array.length > size
    ? [array.slice(0, size), ...divideArray(array.slice(size), size)]
    : [array];
}

function getRandomString(length) {
  const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const result = [];
  const charSetLength = charSet.length;
  for (let i = 0; i < length; i++) {
    result.push(charSet.charAt(Math.floor(Math.random() * charSetLength)));
  }
  return result.join('');
}

export function setUniqueScreenCode() {
  // This is a drop in replacement to socket id.
  // Actually better because it does not change on page refresh.
  if (!localStorage.getItem(UNIQUE_SCREEN_CODE)) {
    localStorage.setItem(UNIQUE_SCREEN_CODE, getRandomString(9));
  }
}
