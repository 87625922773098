import localforage from 'localforage';

export async function cacheData(key: string, data: any): Promise<any | null> {
  try {
    if (await localforage.getItem(key)) await localforage.removeItem(key);

    return await localforage.setItem(key, data);
  } catch (error) {
    return null;
  }
}

export default { cacheData };
