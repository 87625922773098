import config from '../config';
import http from '@/utils/http';

export async function apiGetScreenLayout(screenId) {
  try {
    const endpoint = `${config.apiUrl}userapi/screens/${screenId}/layout`;

    return await http.get(endpoint);
  } catch (error) {
    console.log("Screen's Layout API failed: ", error?.message);
    return null;
  }
}

export async function apiGetScreenLayoutChildren(screenId) {
  try {
    return await http.get(`${config.apiUrl}userapi/screens/${screenId}/layout/children`);
  } catch (error) {
    console.log("Layout's children API failed: ", error?.message);
    return null;
  }
}

export async function apiGetScreenLayoutMediaAsset(screenId) {
  const response = await http.get(`${config.apiUrl}userapi/screens/${screenId}/layout/media-asset`);

  return response.data;
}
