import localforage from 'localforage';

import { SESSION_TOKEN_ENUM, REFRESH_TOKEN_ENUM, SCREEN_ID_ENUM } from '@/constants';
import { ScreenToken } from '@/types/api/login';

export function isLoggedIn() {
  return !!localStorage.getItem(SESSION_TOKEN_ENUM);
}

export function handleLogin(data: ScreenToken) {
  try {
    localStorage.setItem(SESSION_TOKEN_ENUM, data['session_token']);
    localStorage.setItem(REFRESH_TOKEN_ENUM, data['refresh_token']);
    localStorage.setItem(SCREEN_ID_ENUM, data['screen_id'].toString());

    localforage.setItem(SESSION_TOKEN_ENUM, data['session_token']);
    localforage.setItem(REFRESH_TOKEN_ENUM, data['refresh_token']);
    localforage.setItem(SCREEN_ID_ENUM, data['screen_id']);
  } catch (error) {
    console.log('Error saving token in storages', error);
  }
}
