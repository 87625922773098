<template>
  <div ref="templateContainer" v-if="tile" class="tile-main-container" :style="tileStyle">
    <template v-if="!isLoading">
      <TemplateLayer v-for="widget in widgets || []" :key="widget.assoc_id" :widget="widget" />
    </template>
    <div v-else class="loader">
      <div class="loader-background">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
  import localforage from 'localforage';

  import TemplateLayer from '@/components/widgets/templates/TemplateLayer.vue';
  import Loader from '@/components/common/Loader.vue';

  import { cacheData } from '@/helpers/caching';
  import { formatTileWidgets, getRBGAColor } from '@/helpers/utils';
  import { SET_LOCAL_TEMPLATE_ITEMS } from '@/store/actions/player';

  import { apiGetScreenTileChildren } from '@/api/tiles';

  export default {
    name: 'TileViewer',

    components: {
      TemplateLayer,
      Loader,
    },

    props: {
      tile: {
        type: Object,
        default: () => null,
      },

      zoom: {
        type: Number,
        default: 1,
      },

      playlistId: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        isLoading: false,
      };
    },

    mounted() {
      this.getWidgets();
    },

    watch: {
      tile(value) {
        if (value) {
          this.getWidgets();
        }
      },
    },

    computed: {
      tileStyle() {
        const tile = this.tile && this.tile.settings ? this.tile.settings : {};

        return {
          backgroundColor: getRBGAColor(tile.backgroundColor, tile.opacity / 100),
          width: `100%`,
          height: `100%`,
          zoom: this.zoom,
          overflow: 'hidden',
        };
      },

      widgets() {
        return this.$store.state.player.localTemplatesItems[this.playlistId]?.[
          this.tile.template_id
        ];
      },
    },

    methods: {
      async getWidgets() {
        try {
          if (this.widgets) return;

          const { screenId } = this.$store.state.player;
          const tileId = this.tile.template_id;
          this.isLoading = true;

          const response = await apiGetScreenTileChildren(tileId, screenId, this.playlistId);

          if (!response) {
            const widgets = await localforage.getItem(
              `local-template-items-${this.playlistId}-${tileId}`,
            );

            if (!widgets) {
              this.$store.commit(SET_LOCAL_TEMPLATE_ITEMS, {
                key: tileId,
                playlistId: this.playlistId,
                widgets: null,
              });

              throw new Error(
                `Local template's children cache fallback failed. Local Template: ${tileId}. Playlist: ${this.playlistId}.`,
              );
            }

            this.$store.commit(SET_LOCAL_TEMPLATE_ITEMS, {
              key: tileId,
              playlistId: this.playlistId,
              widgets,
            });

            return;
          }

          const formatedWidgets = formatTileWidgets(response.data);

          await cacheData(`local-template-items-${this.playlistId}-${tileId}`, formatedWidgets);

          this.$store.commit(SET_LOCAL_TEMPLATE_ITEMS, {
            key: tileId,
            playlistId: this.playlistId,
            widgets: formatedWidgets,
          });
        } catch (error) {
          console.log('getWidgets ~ error:', error);
        } finally {
          this.isLoading = false;
        }
      },
    },

    provide() {
      return {
        template: () => this.tile,
      };
    },
  };
</script>

<style lang="scss" scoped>
  .tile-main-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
    box-shadow: none;
    margin-top: 10px;
    transform: translateY(20px);
    background: none;

    &::before {
      left: 75px;
    }

    &::after {
      left: 45px;
    }

    .loader-background {
      background-color: rgba(0, 0, 0, 0.25);

      border-radius: 10px;

      height: min(140px, 80%);
      width: min(140px, 80%);
    }
  }
</style>
