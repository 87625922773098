<template>
  <div class="content-styles rich-text-widget" :style="style" v-html="widget.object.userText"></div>
</template>

<script>
  import playerMixins from '@/models/player';
  import { simpleTypeMixin } from '@/helpers';

  export default {
    name: 'RichtextWidget',

    mixins: [playerMixins, simpleTypeMixin],

    props: {
      widget: {
        type: Object,
        required: true,
        default: () => {},
      },
    },

    data() {
      return {
        style: {},
      };
    },

    methods: {
      renderWidget() {
        this.style = Object.assign({}, this.defaultStyling(this.widget), {
          fontSize: `${this.widget.object.textSize}px`,
        });
      },
    },

    mounted() {
      this.renderWidget();
    },
  };
</script>

<style lang="scss" scoped>
  .rich-text-widget {
    position: absolute;
    overflow: hidden;
  }
  .content-styles {
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    ::v-deep {
      /* Headings */
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: inherit;

        margin-bottom: 0.1rem;

        &:empty:before {
          content: ' ';
          white-space: pre;
        }
      }
      h1 {
        font-size: 2em;
      }

      h2 {
        font-size: 1.5em;
      }

      h3 {
        font-size: 1.17em;
      }

      h4 {
        font-size: 1em;
      }

      h5 {
        font-size: 0.83em;
      }

      h6 {
        font-size: 0.67em;
      }

      /* Other Text Styling */
      p {
        margin-bottom: 0;
        line-height: 1.2em;

        &:empty:before {
          content: ' ';
          white-space: pre;
        }
      }

      strong {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      u {
        text-decoration: underline;
      }

      span[style*='text-decoration-line: underline'] {
        text-decoration: underline;
      }

      /* Lists */
      ul,
      ol {
        margin-bottom: 1em;
        padding-left: 2em;
      }

      li {
        margin: 0.5em 0;
      }
    }
  }
</style>
